<template>
  <footer
    v-show="showFooter"
    id="footer"
    class="page-footer"
  >
    <Button
      v-if="false"
      class="btn-logos gray"
    >
      <div class="logos">
        <!-- <img :src="require('@/assets/svg/footer-logos.svg')"> -->
        <img :src="require('@/assets/svg/aquabahn.svg')">
        <img :src="require('@/assets/svg/slamjam.svg')">
        <img :src="require('@/assets/svg/rv.svg')">
      </div>
    </Button>
    <Button
      class="btn-menu"
      hover
      @click.native="toggleMenu"
    >
      <div class="text flex-center typo--title t-uppercase t-center">
        {{ menuIsOpen ? 'Close' : 'Menu' }}
      </div>
    </Button>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex';
import Button from '@/components/ui/button';

export default {
  name: 'Footer',
  components: {
    Button,
  },
  methods: {
    toggleMenu() {
      this.$store.commit('SET_MENU_IS_OPEN', !this.menuIsOpen);
    },
  },
  computed: {
    ...mapGetters(['options', 'currentPost', 'menuIsOpen']),
    showFooter() {
      if (this.$route.meta.hideFooter) {
        return false;
      }
      if (!this.$route.meta.customView && !this.currentPost) {
        return false;
      }
      return true;
    },
    footer() {
      const { footer } = this.options;
      return footer;
    },
  },
};
</script>

<style lang="scss" scoped>
footer {
  position: fixed;
  bottom: 0;
  right: 0;
  width: fit-content;
  max-width: 100%;
  z-index: 100;
}

footer,
.button {
    @include mq($until: s) {
    width: 100%;
  }
}

.menu-closed .btn-logos {
  @include mq($until: s) {
    display: none;
  }
}

.logos {
  display: grid;
  justify-items: center;
  grid-auto-flow: row;
  @include mq(s) {
    grid-auto-flow: column;
  }
  gap: 7px 0px;
  padding: 2px;

  img {
    height: 19px;
    @include mq(s) {
      height: 24px;
    }
  }
}

.btn-menu {
  cursor: pointer;
  user-select: none;
  @include mq(s) {
    display: none;
  }

  .text {
    padding: 3px; // var spacer
  }
}
</style>
