<template>
  <div class="thumb bordered-row">
    <div class="video bordered-cell span-s-5">
      <!-- <router-link
        :to="$relativeUrl(data.link)"
        class="thumb-link bordered-cell"
      > -->
      <YtEmbed
        v-if="productData.value.tutorial.video.value.url"
        :data="productData.value.tutorial.video"
      />
      <!-- </router-link> -->
    </div>
    <div class="tutorial wisdom-grid bordered-row span-s-2">
      <div
        class="title bordered-cell typo--title gray"
        v-html="data.title.rendered"
      />
      <div class="wisdom-row bordered-row">
        <a
          :href="productData.value.tutorial.pdf.url"
          class="icon a-unstyled bordered-cell span-1"
        >
          <img :src="require('@/assets/images/logoPDF.jpg')">
        </a>
        <div class="download wisdom-grid bordered-row span-5">
          <div class="bordered-cell" />
          <Link
            class="link bordered-cell gray"
            :data="{
              url: productData.value.tutorial.pdf.url,
              title: 'DOWNLOAD PDF',
              target: '_blank',
            }"
            theme="hover"
          />
        </div>
      </div>
      <div class="empty bordered-cell" />
    </div>
  </div>
</template>

<script>
import YtEmbed from '@/components/media/yt-embed';
import Link from '@/components/typo/link';

export default {
  name: 'TutorialThumb',
  components: {
    YtEmbed,
    Link,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    productData() {
      return this.data.gds_blocks.filter((block) => block.blockName === 'acf/product')[0].attrs.data;
    },
    colorsArray() {
      const colors = this.productData.value.colors || [{ color: 'rgba(0,0,0,0)' }];
      return colors.map((obj) => obj.color);
    },
    sizesArray() {
      const sizes = this.productData.value.sizes || [];
      return sizes.map((obj) => obj.size);
    },
  },
};
</script>

<style lang="scss" scoped>
.thumb {
  display: grid;
  grid-template-rows: min-content min-content;
  @include mq(s) {
    grid-template-rows: auto;
    grid-template-columns: repeat(7, 1fr);
  }

  // grid-auto-rows: min-content;

  // .image {
  //   display: grid;
  //   grid-auto-flow: row;

  //   .name {
  //     text-transform: uppercase;
  //   }
  // }

  // .name,
  // .buy-now,
  // .price,
  // .sizes {
  //   text-align: center;
  //   padding: 3px; // var spacer
  //   user-select: none;
  // }

  .tutorial {
    grid-template-rows: min-content min-content 1fr;
    @include mq($until: s) {
      grid-template-rows: min-content min-content;
      order: -1;

      .empty {
        display: none;
      }
    }

    .title,
    .download .link {
      padding: 3px; // var spacer
      text-transform: uppercase;
      text-align: center;
      @include flex-center;
    }
    .icon {
      padding: 6px; // var spacer
      background: var(--white);

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .download {
      grid-template-rows: 1fr min-content;
    }
  }
  .color {
    background: var(--bg);
  }
}
</style>
