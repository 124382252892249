import Title from '@/components/typo/title';
import Richtext from '@/components/typo/richtext';
import Link from '@/components/typo/link';
import Figure from '@/components/media/figure';
import VideoPlayer from '@/components/media/video';
import Gallery from '@/components/media/gallery';
import Archive from '@/components/blocks/archive';
import Posts from '@/components/blocks/posts';
import Product from '@/components/blocks/product';
import Grid from '@/components/blocks/grid';

const components = new Map();

components.set('title', Title);
components.set('paragraph', Richtext);
components.set('link', Link);
components.set('image', Figure);
components.set('video', VideoPlayer);
components.set('gallery', Gallery);
components.set('archive', Archive);
components.set('post', Posts);
components.set('product', Product);
components.set('grid', Grid);

export default components;
