<template>
  <header
    v-show="!$route.meta.hideHeader"
    id="header"
  >
    <div
      v-scroll-lock="$mq.isMobileS && menuIsOpen"
      class="grid-wrapper"
    >
      <div class="block-grid bordered-grid">
        <div class="header-row t-center t-uppercase typo--title bordered-row">
          <div class="logo bordered-cell">
            <div>
              <img :src="require('@/assets/svg/wisdomthirdwave.svg')">
            </div>
          </div>
          <!-- <div class="side bordered-cell span-s-2"> -->
          <Link
            :data="{
              url: 'https://rayonvert.international/',
              title: 'Rayon Vert',
              target: '_blank',
            }"
            theme="hover"
            class="side bordered-cell span-s-2"
          />
          <!-- </div> -->
          <!-- <div class="bordered-cell span-s-3 gray"> -->
          <Link
            :data="{
              url: '/',
              title: '&#8220;The Wisdom of the Third Wave&#8221;',
              target: false,
            }"
            theme="hover"
            class="bordered-cell span-s-3 gray"
          />
          <!-- smart double quotes: &#8220; &#8221; // basic double quotes: &#34; -->
          <!-- </div> -->
          <!-- <div class="side bordered-cell span-s-2"> -->
          <Link
            :data="{
              url: 'https://slamjam.com/',
              title: 'Slam Jam',
              target: '_blank',
            }"
            theme="hover"
            class="side bordered-cell span-s-2"
          />
          <!-- </div> -->
        </div>
        <div class="menu-wrapper bordered-row">
          <Menu class="menu-row bordered-row span-s-5" />
          <div
            class="free-cell bordered-cell span-s-2 gray typo--p t-center"
            :class="{hide: $route.name === 'Product'}"
          >
            <!-- <div
              v-if="$route.name === 'Product'"
              class="free-grid bordered-grid gray"
            >
              <div class="bordered-row">
                <div class="free-text bordered-cell flex-center">
                  Go to //url.com to buy the collection
                </div>
              </div>
              <div class="bordered-row">
                <Link
                  class="buy-now free-action bordered-cell flex-center typo--title"
                  :data="{
                    url: '#!',
                    title: 'BUY NOW',
                    target: '_blank',
                  }"
                  theme="hover"
                />
              </div>
            </div> -->

            <div class="free-grid bordered-grid gray">
              <!-- <div
              v-else
              class="free-grid bordered-grid gray"
            > -->
              <div class="bordered-row">
                <div class="free-text bordered-cell">
                  Do you want to know more? Register here for new updates:<br><br>
                  By clicking on "SUBSCRIBE" you agree to receive our newsletter (Read the full <a
                    href="https://www.iubenda.com/privacy-policy/15366686"
                    target="_blank"
                  >Privacy Policy</a>).
                </div>
              </div>
              <div
                v-if="!mc.success && !mc.already"
                class="email bordered-row"
                @keyup.enter="submit"
              >
                <input
                  ref="email"
                  v-model="email"
                  type="email"
                  name="email"
                  placeholder="YOUR EMAIL HERE"
                  autocomplete="email"
                  class="bordered-cell flex-center gray"
                >
                <div
                  class="button bordered-cell"
                  @click="submit"
                >
                  SUBSCRIBE
                </div>
              </div>
              <div
                v-else-if="mc.already"
                class="bordered-row"
              >
                <div class="bordered-cell">
                  ALREADY SUBSCRIBED
                </div>
              </div>
              <div
                v-else-if="mc.success"
                class="bordered-row"
              >
                <div class="bordered-cell">
                  THANK YOU
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import Menu from '@/components/ui/menu';
import Link from '@/components/typo/link';

export default {
  name: 'Header',
  components: {
    Menu,
    Link,
  },
  data() {
    return {
      email: '',
      mc: {
        success: false,
        avoid: false,
        already: false,
      },
    };
  },
  computed: {
    ...mapGetters(['options', 'menuIsOpen']),
  },
  methods: {
    validateEmail(email) {
      return String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    },
    submit() {
      const emailIsValid = this.validateEmail(this.email) !== null;
      if (!this.mc.avoid && emailIsValid) {
        this.mc.avoid = true;
        const request = {
          fn: 'mailchimp',
          params: {
            list_id: this.options.extra.mailchimp_list_id.value,
            email: this.email,
          },
        };
        this.$store.dispatch('sendRequest', request)
          .then((response) => {
            if (typeof response.data.status === 'string' && response.data.status.toLowerCase().trim() === 'subscribed') {
              this.mc.success = true;
            } else if (typeof response.data.status === 'number' && response.data.status === 400 && response.data.title?.toLowerCase().trim() === 'member exists') {
              this.mc.already = true;
            } else {
              this.mc.success = false;
            }
            this.mc.avoid = false;
          })
          .catch(() => {
            this.mc.success = false;
            this.mc.avoid = false;
            this.mc.already = false;
          });
      }
    },
  },
};
</script>

<style lang="scss">
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  body:not(.menu-closed) & {
    @include mq($until: s) {
      height: 100%;
      background: var(--grey-bg);
    }
  }
}

.grid-wrapper {
  min-width: fit-content;
  @include mq($until: s) {
    max-height: calc(100% - var(--bottom));
    overflow: auto;
  }
}

.block-grid {
  display: grid;

  .header-row {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: min-content;
    @include mq(s) {
      grid-auto-flow: column;
      grid-auto-columns: 1fr;
    }

    & > * {
      @include flex-center;
    }
    & > .link,
    & > * > * {
      padding: 9px;
      @include mq(s) {
        padding: 2px 14px;
      }
    }

    .link {
      width: 100%;
    }

    .logo {
      @include mq(s) {
        position: fixed;
        top: 50%;
        left: 0;
        width: calc(100% / 14);
        transform: translateY(-100%);
        z-index: 100;

        @at-root .bordered-grid .bordered-row .bordered-cell.logo {
          @include outer-el-border;
          height: auto;

          & > * {
            width: 100%;
            background: var(--grey-bg);
            @include inner-el-border;
            padding: 6px;
          }
        }
      }

      img {
        display: block;
        @include mq($until: s) {
          height: 80px;
        }
      }
    }
  }
}

.menu-closed { // add class to body
  .header-row .logo,
  // .header-row .link,
  .header-row .side,
  .menu-wrapper {
    @include mq($until: s) {
      display: none;
    }
  }
}

// .header-row .side {
//   background: var(--white);
// }

.gray {
  background: var(--grey-bg);
}

.menu-wrapper,
.menu-row {
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  @include mq(s) {
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
  }
}

.hide {
  @include mq(s) {
    display: none;
  }
}

.free {
  &-cell {
    position: relative;
    // min-width: 300px;
    min-height: 25px;

    & > * {
      // position: absolute;
      // width: calc(100% + var(--border-width) + var(--center));
      @include mq(s) {
        position: absolute;
        top: calc(0px - var(--border-width));
        right: calc(0px - var(--border-width));
        width: calc(100% + 2 * (var(--edge) + var(--center)));
        // max-width: calc(100vw / 7 * 2);
      }

      & > * {
        height: 100%;
      }
    }
  }
  &-grid {
    display: grid;
    grid-auto-flow: row;
  }
  &-text {
    padding: 3px; // var spacer
  }
  &-action {
    padding: 3px; // var spacer
  }
}

.buy-now {
  display: block !important;
  height: 100%;
  padding: 3px; // var spacer
  cursor: pointer;
}

.email {
      display: grid;
      grid-template-columns: 1fr min-content;
      align-items: center;
      justify-self: stretch;

      & > * {
        // width: 100%;
        // background: var(--grey-bg);
        padding: 3px;
      }
      input {
        width: 100%;
        height: 100%;
        text-overflow: ellipsis;
        border: none;
        -webkit-appearance: none;
        border-radius: 0;
        line-height: inherit;

        outline: none;
        outline-offset: 0;

        &:focus,
        &:focus-visible {
          outline: none;
          outline-offset: 0;
        }
      }
      .button {
        display: flex;
        align-items: center;
        height: 100%;
        cursor: pointer;
        @include mq($and: $hover) {
          &:hover {
            background: var(--hover-col);
          }
        }
      }
    }
</style>
