<template>
  <main>
    <Figure
      v-if="post.gds_product_bg"
      :data="{
        value: {
          image: post.gds_product_bg,
        },
        settings: {},
      }"
      :lazyload="false"
      theme="background"
      disable-ratio
    />

    <div class="wisdom-grid bordered-grid">
      <div class="wisdom-row bordered-row">
        <div class="category bordered-row span-1">
          <div
            class="name bordered-cell flex-center"
            v-html="post.gds_taxonomies.categories[0].name"
          />
          <div class="bordered-cell" />
        </div>
        <Title
          v-if="post.title.rendered"
          :value="post.title.rendered"
          tag="h1"
          class="bordered-cell span-6 span-s-5 t-center"
        />
        <div class="empty bordered-cell span-1" />
      </div>

      <Gutenberg
        :data="post.gds_blocks"
        :extras="{cat_slug: post.gds_taxonomies.categories[0].slug}"
      />
    </div>
  </main>
</template>

<script>
import data from '@/mixins/data';

import Gutenberg from '@/components/blocks';
import Title from '@/components/typo/title';
import Figure from '@/components/media/figure';

export default {
  name: 'Product',
  components: {
    Gutenberg,
    Title,
    Figure,
  },
  mixins: [data],
};
</script>

<style lang="scss" scoped>
// .wisdom-grid {
//   --offset: calc(var(--border-width) - var(--center));
//   margin-top: var(--offset);
//   margin-bottom: var(--offset);
// }

.title {
  margin: 0;
  padding: 6px;
}
.category {
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: 1fr;
}
.empty {
  @include mq($until: s) {
    display: none;
  }
}
</style>
