<template>
  <div class="archive">
    <div
      v-if="data.value.title"
      class="title-grid wisdom-grid bordered-grid"
    >
      <div class="wisdom-row bordered-row">
        <div class="empty bordered-cell span-1" />
        <Title
          v-if="data.value.title"
          :value="data.value.title"
          tag="h1"
          class="bordered-cell span-s-5 t-center"
        />
        <div class="empty bordered-cell span-1" />
      </div>
    </div>
    <Grid
      v-if="items.length > 0"
      :col="colTemplate"
      row-gap="0"
      column-gap="0"
      class="bordered-grid"
    >
      <div
        v-for="(item, index) in items"
        :key="index"
        class="item"
      >
        <component
          :is="thumbs.get(thumbsType)"
          :data="item"
        />
      </div>
      <div
        v-if="isProduct && items.length % 2 !== 0"
        class="empty bordered-row"
      >
        <div class="bordered-cell" />
      </div>
    </Grid>

    <div
      v-show="items && data.settings.infinite_scroll"
      ref="infinite"
      class="infinite"
    />

    <Link
      v-if="data.value.link"
      :data="data.value.link"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import medusa from '@/assets/js/observer';

import ProductThumb from '@/components/thumb';
import TutorialThumb from '@/components/thumb/tutorial-thumb';
import Title from '@/components/typo/title';
import Link from '@/components/typo/link';

const thumbs = new Map();
thumbs.set('product', ProductThumb);
thumbs.set('tutorial', TutorialThumb);

export default {
  name: 'Archive',
  components: {
    Title,
    Link,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      thumbs,
      request: null,
      items: [],
      offset: 0,
      placeholder: 'Loading',
    };
  },
  computed: {
    ...mapGetters(['postType']),
    thumbsType() {
      let thumbsType = null;
      switch (this.$route.params.slug) {
      case 'diy-sewing-kits':
        thumbsType = 'product';
        break;

      case 'merchandise':
        thumbsType = 'product';
        break;

      case 'instructions':
        thumbsType = 'tutorial';
        break;

      default:
        thumbsType = null;
        break;
      }
      // return this.$route.params.slug === 'products' ? 'product' : this.$route.params.slug === 'instructions' ? 'tutorial' : null;
      return thumbsType;
    },
    isProduct() {
      return this.$route.params.slug === 'diy-sewing-kits' || this.$route.params.slug === 'merchandise';
    },
    colTemplate() {
      return this.isProduct ? { default: 12, s: 6 } : { default: 12 };
    },
  },
  mounted() {
    this.setRequest();

    this.loadItems().then(() => {
      if (this.data.settings.infinite_scroll) {
        this.$nextTick(this.setInfiniteScroll);
      } else {
        this.$root.$children[0].lazyObserve(this.$el);
      }
    });
  },
  beforeDestroy() {
    if (medusa.ref && medusa.ref.idList.includes('infinite-scroll')) {
      medusa.ref.removeTarget('infinite-scroll');
    }
  },
  methods: {
    async loadItems() {
      const items = await this.$store.dispatch('getItems', this.request);
      if (items) {
        this.items = [...this.items, ...items];
      }

      if (this.$route.params.slug === 'instructions') { // remove any item that doesn't have a pdf tutorial
        this.items = items.filter(({ gds_blocks: [{ attrs: { data: { value: { tutorial: { pdf } } } } }] }) => pdf !== false && pdf.url !== '');
      }

      return items;
    },
    setRequest() {
      const type = this.postType(this.data.value.post_type).rest_base;
      const per_page = parseInt(this.data.settings.posts_per_page, 10);
      const order = this.data.settings.order ? 'desc' : 'asc';
      const orderby = this.data.settings.order_by;
      const { offset } = this;

      const filters = {};

      this.data.settings.filters.forEach((filter) => {
        const string = filter.split(':');
        // eslint-disable-next-line prefer-destructuring
        filters[string[0]] = parseInt(string[1], 10);
      });

      this.request = {
        type,
        params: {
          ...filters,
          per_page,
          offset,
          order,
          orderby,
          lang: this.$store.state.lang,
        },
      };
    },
    setInfiniteScroll() {
      medusa.ref.addTarget({
        id: 'infinite-scroll',
        threshold: 0.0,
        nodes: [],
        mode: 'default',
        callback: this.setOffset,
        autoremove: false,
      });

      medusa.ref.pushToTarget('infinite-scroll', this.$el.querySelector('.infinite'));
    },
    setOffset(entry) {
      if (entry.isIntersecting) {
        this.requestOffset();
      }
    },
    requestOffset() {
      const { height, top } = this.$el.getBoundingClientRect();
      this.offset += parseInt(this.data.settings.posts_per_page, 10);
      this.setRequest();
      this.loadItems().then((newItems) => {
        this.$root.$children[0].lazyObserve(this.$el);

        if (newItems.length === 0) {
          medusa.ref.removeTarget('infinite-scroll');
        } else if ((window.pageYOffset + window.innerHeight) >= height + top) {
          this.requestOffset();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.archive {
  position: relative;

  .title-grid {
    --offset: calc(2 * var(--edge));
    margin-top: var(--offset);
    margin-bottom: var(--offset);
    @include mq($until: s) {
      position: sticky;
      top: calc(var(--top) - var(--edge) - var(--center));
      z-index: 50;
    }

    .title {
      margin: 0;
      padding: 6px;
    }
  }

  .empty {
    @include mq($until: s) {
      display: none;
    }
  }
}
.infinite {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
  height: 50vh;
}
</style>
