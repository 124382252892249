<template>
  <div :class="['button', {hover: hover}]">
    <div class="button--content gray">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Button',
  props: {
    hover: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  display: inline-block;
  max-width: calc(100% - 2 * var(--edge));
  @include outer-el-border;

  &--content {
    width: 100%;
    // background: var(--grey-bg);
    @include inner-el-border;

    @include mq($and: $hover) {
      @at-root .hover &:hover {
        background: var(--hover-col);
      }
    }
  }
}
</style>
