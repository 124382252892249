var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"forofor"},[_c('div',{staticClass:"block-grid bordered-grid"},[_c('div',{staticClass:"row bordered-row"},[_c('div',{staticClass:"cell bordered-cell span-s-2"},[_c('Figure',{attrs:{"data":{
            value: {
              image: _vm.image,
            },
            settings: {}
          },"lazyload":false,"theme":"cover","disable-ratio":""}})],1),_c('div',{staticClass:"cell bordered-cell span-s-2"},[_c('Figure',{attrs:{"data":{
            value: {
              image: _vm.image,
            },
            settings: {}
          },"lazyload":false,"theme":"cover","disable-ratio":""}})],1),_c('div',{staticClass:"block-grid span-s-1"},[_c('div',{staticClass:"row bordered-row"},[_c('div',{staticClass:"cell bordered-cell span-s-1"},[_c('Figure',{attrs:{"data":{
                value: {
                  image: _vm.image,
                },
                settings: {}
              },"lazyload":false,"theme":"cover","disable-ratio":""}})],1)]),_c('div',{staticClass:"row bordered-row"},[_c('div',{staticClass:"cell bordered-cell span-s-1"},[_c('Figure',{attrs:{"data":{
                value: {
                  image: _vm.image,
                },
                settings: {}
              },"lazyload":false,"theme":"cover","disable-ratio":""}})],1)])]),_c('div',{staticClass:"block-grid span-s-1"},[_c('div',{staticClass:"row bordered-row"},[_c('div',{staticClass:"cell bordered-cell span-s-1"},[_c('Figure',{attrs:{"data":{
                value: {
                  image: _vm.image,
                },
                settings: {}
              },"lazyload":false,"theme":"cover","disable-ratio":""}})],1)]),_c('div',{staticClass:"row bordered-row"},[_c('div',{staticClass:"cell bordered-cell span-s-1"},[_c('Figure',{attrs:{"data":{
                value: {
                  image: _vm.image,
                },
                settings: {}
              },"lazyload":false,"theme":"cover","disable-ratio":""}})],1)])]),_c('div',{staticClass:"block-grid span-s-1"},[_c('div',{staticClass:"row bordered-row"},[_c('div',{staticClass:"cell bordered-cell span-s-1"},[_c('Figure',{attrs:{"data":{
                value: {
                  image: _vm.image,
                },
                settings: {}
              },"lazyload":false,"theme":"cover","disable-ratio":""}})],1)]),_c('div',{staticClass:"row bordered-row"},[_c('div',{staticClass:"cell bordered-cell span-s-1"},[_c('Figure',{attrs:{"data":{
                value: {
                  image: _vm.image,
                },
                settings: {}
              },"lazyload":false,"theme":"cover","disable-ratio":""}})],1)])]),_c('div',{staticClass:"block-grid span-s-1"},[_c('div',{staticClass:"row bordered-row"},[_c('div',{staticClass:"cell bordered-cell span-s-1"},[_c('Figure',{attrs:{"data":{
                value: {
                  image: _vm.image,
                },
                settings: {}
              },"lazyload":false,"theme":"cover","disable-ratio":""}})],1)]),_c('div',{staticClass:"row bordered-row"},[_c('div',{staticClass:"cell bordered-cell span-s-1"},[_c('Figure',{attrs:{"data":{
                value: {
                  image: _vm.image,
                },
                settings: {}
              },"lazyload":false,"theme":"cover","disable-ratio":""}})],1)])]),_c('div',{staticClass:"cell bordered-cell span-s-2"},[_c('Figure',{attrs:{"data":{
            value: {
              image: _vm.image,
            },
            settings: {}
          },"lazyload":false,"theme":"cover","disable-ratio":""}})],1)]),_c('div',{staticClass:"row bordered-row"},[_c('div',{staticClass:"block-grid span-s-1"},[_c('div',{staticClass:"row bordered-row"},[_c('div',{staticClass:"cell bordered-cell span-s-1"},[_c('Figure',{attrs:{"data":{
                value: {
                  image: _vm.image,
                },
                settings: {}
              },"lazyload":false,"theme":"cover","disable-ratio":""}})],1)]),_c('div',{staticClass:"row bordered-row"},[_c('div',{staticClass:"cell bordered-cell span-s-1"},[_c('Figure',{attrs:{"data":{
                value: {
                  image: _vm.image,
                },
                settings: {}
              },"lazyload":false,"theme":"cover","disable-ratio":""}})],1)])]),_c('div',{staticClass:"block-grid span-s-1"},[_c('div',{staticClass:"row bordered-row"},[_c('div',{staticClass:"cell bordered-cell span-s-1"},[_c('Figure',{attrs:{"data":{
                value: {
                  image: _vm.image,
                },
                settings: {}
              },"lazyload":false,"theme":"cover","disable-ratio":""}})],1)]),_c('div',{staticClass:"row bordered-row"},[_c('div',{staticClass:"cell bordered-cell span-s-1"},[_c('Figure',{attrs:{"data":{
                value: {
                  image: _vm.image,
                },
                settings: {}
              },"lazyload":false,"theme":"cover","disable-ratio":""}})],1)])]),_c('div',{staticClass:"cell bordered-cell span-s-6"},[_c('Figure',{attrs:{"data":{
            value: {
              image: _vm.image,
            },
            settings: {}
          },"lazyload":false,"theme":"cover","disable-ratio":""}})],1),_c('div',{staticClass:"cell bordered-cell span-s-2"},[_c('Figure',{attrs:{"data":{
            value: {
              image: _vm.image,
            },
            settings: {}
          },"lazyload":false,"theme":"cover","disable-ratio":""}})],1)]),_c('div',{staticClass:"row bordered-row"},[_c('div',{staticClass:"cell bordered-cell span-s-4"},[_c('Figure',{attrs:{"data":{
            value: {
              image: _vm.image,
            },
            settings: {}
          },"lazyload":false,"theme":"cover","disable-ratio":""}})],1),_c('div',{staticClass:"cell bordered-cell span-s-2"},[_c('Figure',{attrs:{"data":{
            value: {
              image: _vm.image,
            },
            settings: {}
          },"lazyload":false,"theme":"cover","disable-ratio":""}})],1),_c('div',{staticClass:"cell bordered-cell span-s-2"},[_c('Figure',{attrs:{"data":{
            value: {
              image: _vm.image,
            },
            settings: {}
          },"lazyload":false,"theme":"cover","disable-ratio":""}})],1),_c('div',{staticClass:"cell bordered-cell span-s-1"},[_c('Figure',{attrs:{"data":{
            value: {
              image: _vm.image,
            },
            settings: {}
          },"lazyload":false,"theme":"cover","disable-ratio":""}})],1),_c('div',{staticClass:"cell bordered-cell span-s-1"},[_c('Figure',{attrs:{"data":{
            value: {
              image: _vm.image,
            },
            settings: {}
          },"lazyload":false,"theme":"cover","disable-ratio":""}})],1),_c('div',{staticClass:"cell bordered-cell span-s-2"},[_c('Figure',{attrs:{"data":{
            value: {
              image: _vm.image,
            },
            settings: {}
          },"lazyload":false,"theme":"cover","disable-ratio":""}})],1),_c('div',{staticClass:"cell bordered-cell span-s-1"},[_c('Figure',{attrs:{"data":{
            value: {
              image: _vm.image,
            },
            settings: {}
          },"lazyload":false,"theme":"cover","disable-ratio":""}})],1),_c('div',{staticClass:"cell bordered-cell span-s-2"},[_c('Figure',{attrs:{"data":{
            value: {
              image: _vm.image,
            },
            settings: {}
          },"lazyload":false,"theme":"cover","disable-ratio":""}})],1),_c('div',{staticClass:"cell bordered-cell span-s-4"},[_c('Figure',{attrs:{"data":{
            value: {
              image: _vm.image,
            },
            settings: {}
          },"lazyload":false,"theme":"cover","disable-ratio":""}})],1)]),_c('div',{staticClass:"row bordered-row"},[_c('div',{staticClass:"cell bordered-cell span-s-1"},[_c('Figure',{attrs:{"data":{
            value: {
              image: _vm.image,
            },
            settings: {}
          },"lazyload":false,"theme":"cover","disable-ratio":""}})],1),_c('div',{staticClass:"cell bordered-cell span-s-1"},[_c('Figure',{attrs:{"data":{
            value: {
              image: _vm.image,
            },
            settings: {}
          },"lazyload":false,"theme":"cover","disable-ratio":""}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }