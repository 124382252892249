var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"thumb bordered-row"},[_c('div',{staticClass:"empty bordered-cell span-2"}),_c('div',{staticClass:"image-name bordered-row span-5"},[_c('router-link',{staticClass:"bordered-cell a-unstyled",attrs:{"to":_vm.$relativeUrl(_vm.data.link)}},[(_vm.data.gds_featured_image)?_c('Figure',{attrs:{"data":{
          value: {
            image: _vm.data.gds_featured_image,
          },
          settings: {}
        },"lazyload":false,"theme":"cover","disable-ratio":""}}):_vm._e()],1),_c('Link',{staticClass:"name bordered-cell typo--p gray",attrs:{"data":{
        url: _vm.data.link,
        title: _vm.data.title.rendered,
        target: false,
      },"theme":"hover"}})],1),_c('div',{staticClass:"empty bordered-cell span-1"}),_c('div',{staticClass:"details bordered-row"},[_c('Link',{staticClass:"buy-now bordered-cell span-4 gray",attrs:{"data":{
        url: _vm.productData.value.shop.url,
        title: 'BUY NOW',
        target: '_blank',
      },"theme":"hover"}}),_c('div',{staticClass:"price bordered-cell span-2",domProps:{"innerHTML":_vm._s(_vm.productData.value.shop.price)}}),_c('div',{staticClass:"sizes bordered-cell span-4"},_vm._l((_vm.sizesArray),function(size,index){return _c('span',{key:index,staticClass:"size",domProps:{"innerHTML":_vm._s(size)}})}),0),_c('div',{staticClass:"colors bordered-row span-2"},_vm._l((_vm.colorsArray),function(color,index){return _c('div',{key:index,staticClass:"color bordered-cell",style:({'--bg': color})})}),0)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }