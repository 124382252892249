<template>
  <nav>
    <div
      v-if="navigationEmpty"
      class="free-cell bordered-cell gray"
    />
    <div
      v-for="item in navigation"
      :key="item.id"
      :class="['bordered-cell', 'gray', ...item.css, {about: item.content.toLowerCase().trim() === 'about'}]"
    >
      <Link
        class="menu-link typo--p t-center flex-center"
        :data="{
          url: item.url,
          title: item.content,
          target: item.target,
        }"
        theme="hover"
      />
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import Link from '@/components/typo/link';

export default {
  name: 'Menu',
  components: {
    Link,
  },
  computed: {
    ...mapGetters(['menu']),
    navigation() {
      const nav = this.menu('navigation');
      return nav ? nav.items : [];
    },
    navigationEmpty() {
      if (this.navigation.length === 0
      || (this.navigation.length === 1 && this.navigation[0].content.toLowerCase().trim() === 'about')) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-link {
  @include flex-center;
  height: 100%;
  padding: 3px; // var spacer
  cursor: pointer;
}

.about {
  @include mq(s) {
    position: fixed;
    top: 50%;
    left: 0;
    width: calc(100% / 7);
    transform: translateY(calc(0px - var(--center)));
    z-index: 100;

    .bordered-grid .bordered-row &.bordered-cell {
      height: auto;
      @include outer-el-border;

      & > * {
        @include inner-el-border;
      }
    }
  }
}
</style>
