<template>
  <div class="gallery">
    <div
      ref="gallery"
      class="swiper-container"
    >
      <div class="swiper-wrapper">
        <div
          v-for="(item, index) in data.value.gallery"
          :key="index"
          class="swiper-slide"
        >
          <div class="img-wrap">
            <Figure
              :data="{
                value: {
                  image: item,
                },
                settings: {},
              }"
              disable-ratio
              :lazyload="false"
              theme="product-gallery"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';

import Figure from '@/components/media/figure';

export default {
  name: 'Gallery',
  components: {
    Figure,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    extraSettings: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      gallery: null,
      settings: {
        grabCursor: true,
        threshold: 2,
        slidesPerView: 1.2,
      },
    };
  },
  mounted() {
    this.gallery = new Swiper(this.$refs.gallery, Object.assign(this.settings, this.extraSettings));
  },
  beforeUnmount() {
    this.gallery.destroy();
  },
};
</script>

<style lang="scss" scoped>
.gallery {
  width: 100%;
  // max-height: calc(100vh - var(--top));
  max-width: calc(100vw - (var(--border-width) * 2));
  @include mq(s) {
    max-width: calc(50vw - (var(--border-width) * 3 / 2));
  }

  .swiper-slide {
    max-width: calc(100% - var(--edge) * 2);

    .img-wrap {
      @include outer-el-border;

      .figure {
        @include inner-el-border;
      }
    }
  }
}
</style>
