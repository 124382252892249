<template>
  <div class="product">
    <div class="wisdom-row bordered-row">
      <div class="sizes wisdom-row bordered-row span-1 typo--title">
        <span
          v-for="(size, index) in sizesArray"
          :key="index"
          class="size bordered-cell gray"
          v-html="size"
        />
      </div>
      <div
        class="price bordered-cell span-1 typo--title gray"
        v-html="data.value.shop.price"
      />
    </div>
    <!-- <div
      v-if="$route.name === 'Product'"
      class="free-grid bordered-grid gray"
    > -->
    <div class="wisdom-row bordered-row t-center gray">
      <div class="free-text bordered-cell flex-center">
        <a
          href="https://slamjam.com/collections/rayon-vert-w3w"
          target="_blank"
          v-html="category === 'merch' ? 'All merch' : 'All kits'"
        />
      </div>
      <!-- </div>
      <div class="bordered-row"> -->
      <Link
        class="buy-now free-action bordered-cell flex-center typo--title"
        :data="{
          url: data.value.shop.url,
          title: 'BUY NOW',
          target: '_blank',
        }"
        theme="hover"
      />
    </div>
    <!-- </div> -->
    <div
      v-if="category === 'merch'"
      class="merch-wrapper bordered-row"
    >
      <div
        v-for="(image, index) in mergedGalleries.value.gallery"
        :key="index"
        class="image bordered-cell"
      >
        <Figure
          :data="{
            value: {
              image: image,
            },
            settings: {}
          }"
          :lazyload="false"
          theme="contain"
          disable-ratio
        />
      </div>
      <div
        v-if="mergedGalleries.value.gallery.length % 2 !== 0"
        class="empty bordered-cell"
      />
    </div>
    <div
      v-else
      class="collection-wrapper bordered-row"
    >
      <!-- <div class="first-block bordered-row"> -->
      <div
        v-if="mergedGalleries.value.gallery.length > 0"
        class="gallery-wrapper bordered-cell"
      >
        <Gallery
          v-if="mergedGalleries.value.gallery.length > 0"
          :data="mergedGalleries"
        />
        <div
          v-if="colorsArray.length > 0"
          class="colors wisdom-grid bordered-grid"
        >
          <div
            v-for="(color, index) in colorsArray"
            :key="index"
            class="color bordered-cell"
            :style="{'--bg': color}"
          />
        </div>
        <!-- </div> -->
      </div>
      <div class="specs-tut">
        <div
          v-if="data.value.specs.length > 0"
          class="specs-wrapper wisdom-row bordered-row"
        >
          <div class="specs span-1">
            <div class="title bordered-cell typo--title">
              Specifiche Prodotto
            </div>
            <div
              v-for="({spec}, index) in data.value.specs"
              :key="index"
              class="content bordered-cell gray"
              v-html="spec"
            />
          </div>
        </div>
        <div
          v-if="data.value.tutorial.pdf.url"
          class="tutorial-wrapper bordered-row"
        >
          <YtEmbed
            v-if="data.value.tutorial.video.value.url"
            :data="data.value.tutorial.video"
            class="video bordered-cell"
          />
          <div class="tutorial">
            <div class="title bordered-cell typo--title gray">
              DOWNLOAD THE PDF
            </div>
            <div class="wisdom-row bordered-row">
              <a
                :href="data.value.tutorial.pdf.url"
                class="icon a-unstyled bordered-cell span-1"
              >
                <img :src="require('@/assets/images/logoPDF.jpg')">
              </a>
              <div class="download span-5">
                <div class="bordered-cell" />
                <a
                  :href="data.value.tutorial.pdf.url"
                  class="link a-unstyled bordered-cell gray"
                  v-html="pdfTitle"
                />
              </div>
            </div>
          </div>
          <div class="all-tuts-wrapper">
            <Link
              class="all-tuts bordered-cell typo--title gray"
              :data="{
                url: '/instructions',
                title: 'GO TO ALL TUTORIALS',
                target: false,
              }"
              theme="hover"
            />
          </div>
        </div>
        <div class="empty bordered-cell" />
      </div>
    </div>
  </div>
</template>

<script>
import Gallery from '@/components/media/gallery';
import Figure from '@/components/media/figure';
import YtEmbed from '@/components/media/yt-embed';
import Link from '@/components/typo/link';

export default {
  name: 'Product',
  components: {
    Gallery,
    Figure,
    YtEmbed,
    Link,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    extras: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
    };
  },
  computed: {
    category() {
      return this.extras?.cat_slug ? this.extras.cat_slug : '';
    },
    colorsArray() {
      const colors = this.data.value.colors || [];
      return colors.map((obj) => obj.color);
    },
    sizesArray() {
      const sizes = this.data.value.sizes || [''];
      return sizes.map((obj) => obj.size);
    },
    pdfTitle() {
      const title = this.data.value.tutorial.pdf.title ? this.data.value.tutorial.pdf.title.toUpperCase() : '';
      return title;
    },
    mergedGalleries() {
      const mergedGalleries = { value: { gallery: [] } };
      const mainGallery = this.data.value.gallery || [];
      const colorGalleries = [];
      if (this.data.value.colors !== false && this.data.value.colors.length) {
        this.data.value.colors.forEach((variant) => {
          const { pics } = variant;
          if (pics && pics.length) {
            colorGalleries.push(...pics);
          }
        });
      }
      mergedGalleries.value.gallery.push(...mainGallery, ...colorGalleries);
      return mergedGalleries;
    },
  },
};
</script>

<style lang="scss" scoped>
.product {

  &,
  .specs-tut,
  .specs,
  .tutorial,
  .download {
    display: grid;
    grid-auto-flow: row;
    gap: var(--center);
  }

  .specs-tut {
    grid-template-rows: min-content min-content;
    @include mq(s) {
      grid-template-rows: min-content min-content 1fr;
    }
  }

  .merch-wrapper {
    display: grid;
    grid-auto-rows: min-content;
    @include mq(s) {
      grid-template-columns: 1fr 1fr;
    }
  }

  .collection-wrapper {
    display: grid;
    grid-auto-flow: row;
    @include mq(s) {
      grid-auto-flow: column;
      grid-template-columns: 1fr 1fr;
    }

    .first-block {
      display: grid;
      grid-auto-flow: row;
      // @include mq(s) {
      //   grid-template-rows: min-content 1fr;
      // }
    }
  }

  .download .link {
    @include mq($and: $hover) {
      &:hover {
        background: var(--hover-col);
      }
    }
  }

  .size,
  .price,
  .specs .title,
  .tutorial .title,
  .all-tuts,
  .download .link {
    padding: 3px; // var spacer
    text-align: center;
    @include flex-center;
  }

  .gallery-wrapper {
    position: relative;
    width: 100%;
    // min-height: calc(100vh - var(--top) - var(--bottom));
    user-select: none;

    .colors {
      --border-offset: calc(0px - var(--center) - var(--edge));
      position: absolute;
      // bottom: var(--border-offset);
      // left: var(--border-offset);
      bottom: 0;
      left: 0;
      z-index: 10;
      @include mq($until: s) {
        grid-auto-flow: column;
        // top: var(--border-offset);
        top: 0;
        bottom: unset;
        width: calc(50% + var(--border-width) / 2);
      }

      .color {
        background: var(--bg);
        height: 30px;
        @include mq(s) {
          height: 42px;
          width: 42px;
        }
      }
    }
  }

  .specs-wrapper,
  .tutorial-wrapper {
    // @include mq($until: s) {
      grid-auto-flow: row;
      grid-auto-rows: min-content;
    // }
  }

  .empty {
    @include mq($until: s) {
      display: none;
    }
  }

  .specs {
    .content {
      padding: 10px; // var spacer
      @include flex-center;
      text-align: center;
    }
  }

  .tutorial-wrapper {
    display: grid;
    grid-template: min-content / 1fr;
    // @include mq(s) {
    //   grid-template: min-content 1fr / 1fr 1fr;
    // }
  }

  .video {
    grid-row: span 2;
  }

  .all-tuts-wrapper {
    display: grid;
    grid-template-rows: 1fr min-content;
  }

  .tutorial {
    @include mq($until: s) {
      order: -1;
    }
    // grid-template-rows: min-content;

    .icon {
      padding: 6px; // var spacer
      background: var(--white);

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .download {
      grid-template-rows: 1fr min-content;
    }
  }
}
</style>>
