<template>
  <!-- <div
    id="app"
    :style="{'--top': `${top}px`, '--bottom': `${bottom}px`}"
  > -->
  <div id="app">
    <AppHeader />
    <transition
      :css="false"
      appear
      mode="out-in"
      @enter="enter"
      @leave="leave"
    >
      <router-view :key="$route.path" />
    </transition>
    <AppFooter />

    <!-- <Modal />
    <Snackbar />
    <Loader /> -->
    <!-- <Cookie v-if="options && options.cookies" /> -->
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import debounce from 'lodash.debounce';
import medusa from '@/assets/js/observer';
import lazyload from '@/mixins/lazyload';

import AppHeader from '@/components/ui/header';
import AppFooter from '@/components/ui/footer';

// import Modal from '@/components/ui/modal';
// import Snackbar from '@/components/ui/snackbar';
// import Loader from '@/components/ui/loader';
// import Cookie from '@/components/ui/cookie';

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
    // Modal,
    // Cookie,
    // Loader,
    // Snackbar,
  },
  mixins: [lazyload],
  computed: {
    ...mapGetters(['currentPost', 'options', 'menuIsOpen']),
  },
  watch: {
    $route() {
      // route has changed -> close menu
      this.$store.commit('SET_MENU_IS_OPEN', false);
    },
    menuIsOpen(val) {
      if (val) {
        document.body.classList.remove('menu-closed');
      } else {
        document.body.classList.add('menu-closed');
      }
      this.setOffsets();
    },
  },
  // data() {
  //   return {
  //     top: 0,
  //     bottom: 0,
  //   };
  // },
  created() {
    medusa.init();
  },
  mounted() {
    // Redirect in local development
    if (window.location.port === '8888') {
      window.location.href = window.location.href.replace('8888', '3000');
    }

    document.body.classList.add('menu-closed');
    this.setOffsets();

    window.addEventListener(
      'resize',
      debounce(() => {
        this.$bus.$emit('windowResized');
        Vue.set(Vue.prototype, '$mq', this.$mq.setMq());
        this.$mq.vh();
        this.setOffsets();
      }, 400),
    );
  },
  methods: {
    enter(el, done) {
      this.$store.commit('SET_TRANSITION', false);
      done();
    },
    leave(el, done) {
      this.$store.commit('SET_TRANSITION', true);
      done();
    },
    setOffsets() {
      // this.top = document.getElementById('header').offsetHeight;
      // this.bottom = document.getElementById('footer').offsetHeight;
      const top = document.getElementById('header').offsetHeight;
      const bottom = document.getElementById('footer').offsetHeight;
      document.documentElement.style.setProperty('--top', `${top}px`);
      document.documentElement.style.setProperty('--bottom', `${bottom}px`);
    },
  },
};
</script>

<style lang="scss">
  @import "@/assets/scss/style.scss";

html {
  background: var(--black);
}

::selection {
  background: var(--black);
  color: var(--white);
}

.app-loaded {
  // min-height: 100vh;//calc(100 * var(--vh));

  #loader {
    display: none;
  }

  #app {
    margin-top: calc(var(--top) - var(--edge) - var(--center));
    padding-bottom: calc(var(--bottom) - var(--border-width));
  }
}
</style>
