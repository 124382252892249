<template>
  <div
    class="video-wrapper"
    :style="{'--bg': background}"
  >
    <div
      :class="['video-player', {filter: filter}, blend]"
    >
      <div
        v-if="data.settings.source === 'url'"
        ref="player"
        class="plyr"
        @click="toggleMute()"
        v-html="data.value.url"
      />

      <video
        v-if="data.settings.source === 'file'"
        ref="player"
        class="plyr"
        :data-poster="data.settings.poster && data.value.poster ? data.value.poster.sizes.l : null"
        playsinline
      >
        <source
          :src="data.value.src.url"
          type="video/mp4"
        >
      </video>

      <Richtext
        v-if="data.value.caption"
        :data="{ innerHTML: data.value.caption }"
      />
    </div>
  </div>
</template>

<script>
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';

import Richtext from '@/components/typo/richtext';

export default {
  name: 'VideoPlayer',
  components: {
    Richtext,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      plyr: null,
    };
  },
  computed: {
    embed() {
      return false;
    },
    filter() {
      return !!this.data.settings.filter;
    },
    blend() {
      const blend = this.data.settings.blend === 'none' ? false : this.data.settings.blend;
      return blend;
    },
    background() {
      return this.data.settings.background;
    },
  },
  mounted() {
    this.plyr = new Plyr(this.$refs.player, {
      playsinline: true,
      autoplay: true,
      loop: true,
      muted: true,
      controls: false,
      clickToPlay: false,
      fullscreen: false,
      storage: {
        enabled: false,
      },
    });
  },
  methods: {
    toggleMute() {
      if (this.plyr) {
        this.plyr.muted = !this.plyr.muted;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.video-wrapper {
  width: 100%;
  background: var(--bg);
}
.video-player {
  position: relative;
  width: 100%;

  &.filter {
    filter: brightness(115%) grayscale(100%) contrast(5000%);
  }
  &.multiply {
    mix-blend-mode: multiply;
  }
  &.screen {
    mix-blend-mode: screen;
  }
  &.difference {
    mix-blend-mode: difference;
  }
}
</style>

<style lang="scss">
.plyr {
  --plyr-color-main: $color-white;
  --plyr-range-track-height: 2px;
  --plyr-range-thumb-height: 0px;
  --plyr-range-thumb-shadow: none;

  .plyr__progress__buffer {
    border-radius: 0px;
  }

  .plyr__progress input[type="range"] {
    border-radius: 0px;
  }

  .plyr__tooltip {
    display: none;
  }

  .plyr__volume {
    width: auto;
    max-width: auto;
    min-width: auto;
  }
}
</style>
