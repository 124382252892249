<template>
  <div class="thumb bordered-row">
    <div class="empty bordered-cell span-2" />
    <div class="image-name bordered-row span-5">
      <router-link
        :to="$relativeUrl(data.link)"
        class="bordered-cell a-unstyled"
      >
        <Figure
          v-if="data.gds_featured_image"
          :data="{
            value: {
              image: data.gds_featured_image,
            },
            settings: {}
          }"
          :lazyload="false"
          theme="cover"
          disable-ratio
        />
      </router-link>
      <Link
        class="name bordered-cell typo--p gray"
        :data="{
          url: data.link,
          title: data.title.rendered,
          target: false,
        }"
        theme="hover"
      />
    </div>
    <div class="empty bordered-cell span-1" />
    <div class="details bordered-row">
      <Link
        class="buy-now bordered-cell span-4 gray"
        :data="{
          url: productData.value.shop.url,
          title: 'BUY NOW',
          target: '_blank',
        }"
        theme="hover"
      />
      <div
        class="price bordered-cell span-2"
        v-html="productData.value.shop.price"
      />
      <div class="sizes bordered-cell span-4">
        <span
          v-for="(size, index) in sizesArray"
          :key="index"
          class="size"
          v-html="size"
        />
      </div>
      <div class="colors bordered-row span-2">
        <div
          v-for="(color, index) in colorsArray"
          :key="index"
          class="color bordered-cell"
          :style="{'--bg': color}"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Figure from '@/components/media/figure';
import Link from '@/components/typo/link';

export default {
  name: 'ProductThumb',
  components: {
    Figure,
    Link,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    productData() {
      return this.data.gds_blocks.filter((block) => block.blockName === 'acf/product')[0].attrs.data;
    },
    colorsArray() {
      const colors = this.productData.value.colors || [{ color: 'rgba(0,0,0,0)' }];
      return colors.map((obj) => obj.color);
    },
    sizesArray() {
      const sizes = this.productData.value.sizes || [];
      return sizes.map((obj) => obj.size);
    },
  },
};
</script>

<style lang="scss" scoped>
.thumb {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: 1fr min-content;
  height: 100%;
  // grid-auto-rows: min-content;

  .image-name {
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: 1fr min-content;
    grid-column: 3 / -1;
    // max-height: calc(100 * var(--vh));
    ::v-deep .image {
      max-height: calc(100vh - var(--top) - var(--bottom));
    }

    & > * {
      display: block;
    }
  }
  .details {
    display: grid;
    grid-template-rows: min-content 1fr;
    grid-template-columns: repeat(6, 1fr);
    grid-column: 2 / -1;

    .sizes,
    .colors {
      display: grid;
      grid-auto-flow: column;
    }
    .sizes {
      // grid-auto-columns: min-content;
      justify-content: center;
      gap: 1em; // var spacer
    }
  }
  .name,
  .buy-now,
  .price,
  .sizes {
    text-align: center;
    text-transform: uppercase;
    padding: 3px; // var spacer
    user-select: none;
  }
  .color {
    background: var(--bg);
  }

  // .empty {
  //   @include mq($until: s) {
  //     display: none;
  //   }
  // }
}
</style>
