<template>
  <main>
    <Figure
      v-if="post.gds_featured_image.url"
      :data="{
        value: {
          image: post.gds_featured_image,
        },
        settings: {},
      }"
      :lazyload="false"
      theme="background"
      disable-ratio
    />
    <Gutenberg
      :data="post.gds_blocks"
    />
  </main>
</template>

<script>
import data from '@/mixins/data';

import Gutenberg from '@/components/blocks';
import Figure from '@/components/media/figure';

export default {
  name: 'Page',
  components: {
    Gutenberg,
    Figure,
  },
  mixins: [data],
};
</script>

<style lang="scss" scoped>

</style>
