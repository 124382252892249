<template>
  <div>
    <section
      v-for="(block, index) in data"
      :key="index"
      :class="['section', `section--${parseBlockName(block.blockName)}`]"
    >
      <component
        :is="components.get(parseBlockName(block.blockName))"
        :data="block.blockName.includes('acf/') ? block.attrs.data : block"
        :extras="extras"
      />
    </section>
  </div>
</template>

<script>
import { parseBlockName } from '@/assets/js/utils';
import components from '@/assets/js/components';

export default {
  name: 'Gutenberg',
  props: {
    data: {
      type: Array,
      required: true,
    },
    extras: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      parseBlockName,
      components,
    };
  },
};
</script>

<style>

</style>
