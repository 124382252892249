<template>
  <main class="forofor">
    <div class="block-grid bordered-grid">
      <div class="row bordered-row">
        <div class="cell bordered-cell span-s-2">
          <Figure
            :data="{
              value: {
                image: image,
              },
              settings: {}
            }"
            :lazyload="false"
            theme="cover"
            disable-ratio
          />
        </div>
        <div class="cell bordered-cell span-s-2">
          <Figure
            :data="{
              value: {
                image: image,
              },
              settings: {}
            }"
            :lazyload="false"
            theme="cover"
            disable-ratio
          />
        </div>
        <div class="block-grid span-s-1">
          <div class="row bordered-row">
            <div class="cell bordered-cell span-s-1">
              <Figure
                :data="{
                  value: {
                    image: image,
                  },
                  settings: {}
                }"
                :lazyload="false"
                theme="cover"
                disable-ratio
              />
            </div>
          </div>
          <div class="row bordered-row">
            <div class="cell bordered-cell span-s-1">
              <Figure
                :data="{
                  value: {
                    image: image,
                  },
                  settings: {}
                }"
                :lazyload="false"
                theme="cover"
                disable-ratio
              />
            </div>
          </div>
        </div>
        <div class="block-grid span-s-1">
          <div class="row bordered-row">
            <div class="cell bordered-cell span-s-1">
              <Figure
                :data="{
                  value: {
                    image: image,
                  },
                  settings: {}
                }"
                :lazyload="false"
                theme="cover"
                disable-ratio
              />
            </div>
          </div>
          <div class="row bordered-row">
            <div class="cell bordered-cell span-s-1">
              <Figure
                :data="{
                  value: {
                    image: image,
                  },
                  settings: {}
                }"
                :lazyload="false"
                theme="cover"
                disable-ratio
              />
            </div>
          </div>
        </div>
        <div class="block-grid span-s-1">
          <div class="row bordered-row">
            <div class="cell bordered-cell span-s-1">
              <Figure
                :data="{
                  value: {
                    image: image,
                  },
                  settings: {}
                }"
                :lazyload="false"
                theme="cover"
                disable-ratio
              />
            </div>
          </div>
          <div class="row bordered-row">
            <div class="cell bordered-cell span-s-1">
              <Figure
                :data="{
                  value: {
                    image: image,
                  },
                  settings: {}
                }"
                :lazyload="false"
                theme="cover"
                disable-ratio
              />
            </div>
          </div>
        </div>
        <div class="block-grid span-s-1">
          <div class="row bordered-row">
            <div class="cell bordered-cell span-s-1">
              <Figure
                :data="{
                  value: {
                    image: image,
                  },
                  settings: {}
                }"
                :lazyload="false"
                theme="cover"
                disable-ratio
              />
            </div>
          </div>
          <div class="row bordered-row">
            <div class="cell bordered-cell span-s-1">
              <Figure
                :data="{
                  value: {
                    image: image,
                  },
                  settings: {}
                }"
                :lazyload="false"
                theme="cover"
                disable-ratio
              />
            </div>
          </div>
        </div>
        <div class="cell bordered-cell span-s-2">
          <Figure
            :data="{
              value: {
                image: image,
              },
              settings: {}
            }"
            :lazyload="false"
            theme="cover"
            disable-ratio
          />
        </div>
      </div>
      <div class="row bordered-row">
        <div class="block-grid span-s-1">
          <div class="row bordered-row">
            <div class="cell bordered-cell span-s-1">
              <Figure
                :data="{
                  value: {
                    image: image,
                  },
                  settings: {}
                }"
                :lazyload="false"
                theme="cover"
                disable-ratio
              />
            </div>
          </div>
          <div class="row bordered-row">
            <div class="cell bordered-cell span-s-1">
              <Figure
                :data="{
                  value: {
                    image: image,
                  },
                  settings: {}
                }"
                :lazyload="false"
                theme="cover"
                disable-ratio
              />
            </div>
          </div>
        </div>
        <div class="block-grid span-s-1">
          <div class="row bordered-row">
            <div class="cell bordered-cell span-s-1">
              <Figure
                :data="{
                  value: {
                    image: image,
                  },
                  settings: {}
                }"
                :lazyload="false"
                theme="cover"
                disable-ratio
              />
            </div>
          </div>
          <div class="row bordered-row">
            <div class="cell bordered-cell span-s-1">
              <Figure
                :data="{
                  value: {
                    image: image,
                  },
                  settings: {}
                }"
                :lazyload="false"
                theme="cover"
                disable-ratio
              />
            </div>
          </div>
        </div>
        <div class="cell bordered-cell span-s-6">
          <Figure
            :data="{
              value: {
                image: image,
              },
              settings: {}
            }"
            :lazyload="false"
            theme="cover"
            disable-ratio
          />
        </div>
        <div class="cell bordered-cell span-s-2">
          <Figure
            :data="{
              value: {
                image: image,
              },
              settings: {}
            }"
            :lazyload="false"
            theme="cover"
            disable-ratio
          />
        </div>
      </div>
      <div class="row bordered-row">
        <div class="cell bordered-cell span-s-4">
          <Figure
            :data="{
              value: {
                image: image,
              },
              settings: {}
            }"
            :lazyload="false"
            theme="cover"
            disable-ratio
          />
        </div>
        <div class="cell bordered-cell span-s-2">
          <Figure
            :data="{
              value: {
                image: image,
              },
              settings: {}
            }"
            :lazyload="false"
            theme="cover"
            disable-ratio
          />
        </div>
        <div class="cell bordered-cell span-s-2">
          <Figure
            :data="{
              value: {
                image: image,
              },
              settings: {}
            }"
            :lazyload="false"
            theme="cover"
            disable-ratio
          />
        </div>
        <div class="cell bordered-cell span-s-1">
          <Figure
            :data="{
              value: {
                image: image,
              },
              settings: {}
            }"
            :lazyload="false"
            theme="cover"
            disable-ratio
          />
        </div>
        <div class="cell bordered-cell span-s-1">
          <Figure
            :data="{
              value: {
                image: image,
              },
              settings: {}
            }"
            :lazyload="false"
            theme="cover"
            disable-ratio
          />
        </div>
        <div class="cell bordered-cell span-s-2">
          <Figure
            :data="{
              value: {
                image: image,
              },
              settings: {}
            }"
            :lazyload="false"
            theme="cover"
            disable-ratio
          />
        </div>
        <div class="cell bordered-cell span-s-1">
          <Figure
            :data="{
              value: {
                image: image,
              },
              settings: {}
            }"
            :lazyload="false"
            theme="cover"
            disable-ratio
          />
        </div>
        <div class="cell bordered-cell span-s-2">
          <Figure
            :data="{
              value: {
                image: image,
              },
              settings: {}
            }"
            :lazyload="false"
            theme="cover"
            disable-ratio
          />
        </div>
        <div class="cell bordered-cell span-s-4">
          <Figure
            :data="{
              value: {
                image: image,
              },
              settings: {}
            }"
            :lazyload="false"
            theme="cover"
            disable-ratio
          />
        </div>
      </div>
      <div class="row bordered-row">
        <div class="cell bordered-cell span-s-1">
          <Figure
            :data="{
              value: {
                image: image,
              },
              settings: {}
            }"
            :lazyload="false"
            theme="cover"
            disable-ratio
          />
        </div>
        <div class="cell bordered-cell span-s-1">
          <Figure
            :data="{
              value: {
                image: image,
              },
              settings: {}
            }"
            :lazyload="false"
            theme="cover"
            disable-ratio
          />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Figure from '@/components/media/figure';

export default {
  name: 'NotFound',
  components: {
    Figure,
  },
  data() {
    return {
      image: {
        id: 746,
        title: '404',
        filesize: 71615,
        url: 'https://dev.gigadesignstudio.com/wisdom.slamjam.com/wp-content/uploads/2022/03/404-e1647563771327.png',
        alt: '',
        description: '',
        caption: '',
        mime_type: 'image/png',
        type: 'image',
        subtype: 'png',
        width: 1472,
        height: 967,
        sizes: {
          thumbnail: 'https://dev.gigadesignstudio.com/wisdom.slamjam.com/wp-content/uploads/2022/03/404-e1647563771327-150x150.png',
          'thumbnail-width': 150,
          'thumbnail-height': 150,
          s: 'https://dev.gigadesignstudio.com/wisdom.slamjam.com/wp-content/uploads/2022/03/404-e1647563771327-600x394.png',
          's-width': 600,
          's-height': 394,
          m: 'https://dev.gigadesignstudio.com/wisdom.slamjam.com/wp-content/uploads/2022/03/404-e1647563771327-1200x788.png',
          'm-width': 1200,
          'm-height': 788,
          l: 'https://dev.gigadesignstudio.com/wisdom.slamjam.com/wp-content/uploads/2022/03/404-e1647563771327.png',
          'l-width': 1472,
          'l-height': 967,
          xl: 'https://dev.gigadesignstudio.com/wisdom.slamjam.com/wp-content/uploads/2022/03/404-e1647563771327.png',
          'xl-width': 1472,
          'xl-height': 967,
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.forofor {
  height: 100vh;

  .block-grid {
    display: grid;
    grid-auto-flow: row;
    gap: var(--center);

    .row {
      display: grid;
      grid-auto-flow: row;
      @include mq(s) {
        grid-auto-flow: column;
        grid-auto-columns: 1fr;
      }

      // .cell {
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      //   min-height: calc(var(--mh) * 1px);
      //   background: var(--bg);

      //   .richtext {
      //     padding: 21px;

      //     ::v-deep p {
      //       margin-bottom: 1em;
      //     }
      //     ::v-deep img {
      //       display: block;
      //       max-width: 100%;

      //       &.aligncenter {
      //         margin: 0 auto;
      //       }
      //       // width: 100%;
      //       // height: 100%;
      //       // object-fit: cover;
      //     }
      //   }
      //   .embed-container {
      //     width: 100%;
      //   }
      // }
    }
  }
}
</style>
