<template>
  <div
    :class="['embed-container', {filter: filter}, blend]"
    :style="{'--bg': background}"
    v-html="link"
  />
</template>

<script>
export default {
  name: 'YtEmbed',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    link() {
      //   <iframe id="ytplayer" type="text/html" width="720" height="405"
      // src="https://www.youtube.com/embed/?autoplay=1&list=PL8yNO_d1oTwLd8l0tyCvoIeen1y-wIHJW&listType=playlist&playsinline=1"
      // frameborder="0" allowfullscreen>

      const origIframe = this.data.value.url;
      const urlRegex = /(src=")(.*?)(")/;
      let url = origIframe.match(urlRegex);

      if (this.data.settings.autoplay && url && url[0] !== '') {
        // add autoplay
        [, , url] = url;
        const queryIdx = url.indexOf('?');
        url += (queryIdx > -1) ? '&' : '?';
        const params = ['autoplay=1', 'mute=1'].join('&');
        const newUrl = [url, params].join('');
        const newIframe = origIframe.replace(urlRegex, `$1${newUrl}$3`);
        return newIframe;
      }
      return origIframe;
    },
    filter() {
      return !!this.data.settings.filter;
    },
    blend() {
      const blend = this.data.settings.blend === 'none' ? false : this.data.settings.blend;
      return blend;
    },
    background() {
      return this.data.settings.background;
    },
  },
};
</script>

<style lang="scss" scoped>
.embed-container {
  position: relative;
  padding-bottom: 56.25%; // 16:9
  height: 0 !important;
  overflow: hidden;
  max-width: 100%;
  // width: 100%;
  background: var(--bg);

  &.filter > ::v-deep * {
    filter: brightness(115%) grayscale(100%) contrast(5000%);
  }
  &.multiply > ::v-deep * {
    mix-blend-mode: multiply;
  }
  &.screen > ::v-deep * {
    mix-blend-mode: screen;
  }
  &.difference > ::v-deep * {
    mix-blend-mode: difference;
  }

  & > ::v-deep * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
