/* eslint-disable no-underscore-dangle */
/* eslint-disable global-require */
/* eslint-disable no-undef */
import Vue from 'vue';
import Cookies from 'js-cookie';
import { sync } from 'vuex-router-sync';
import { InlineSvgPlugin } from 'vue-inline-svg';
import Vue2TouchEvents from 'vue2-touch-events';
import VueGtag from 'vue-gtag';
import axios from 'axios';
import VScrollLock from 'v-scroll-lock';
import 'normalize.css';

import { loadScript } from '@/assets/js/utils';
import App from './App.vue';

import '@/assets/js/eventBus';
import '@/assets/js/mq';
import '@/assets/js/layout';

Vue.config.productionTip = false;

Vue.use(InlineSvgPlugin);
Vue.use(Vue2TouchEvents);
Vue.use(VScrollLock);

(async () => {
  if (typeof __VUE_WORDPRESS__ === 'undefined') {
    const axiosInstance = axios.create({ baseURL: `${process.env.VUE_APP_PROXY_TARGET}${process.env.VUE_APP_BASE_URL}wp-json/gds/` });
    try {
      const { data: radl } = await axiosInstance.get('radl/');
      window.__VUE_WORDPRESS__ = radl;
    } catch (e) {
      console.error(e);
    }
  }

  const router = require('./router');
  const store = require('./store');
  sync(store.default, router.default);
  const { options } = __VUE_WORDPRESS__.state;
  if (options && options.extra && options.cookies && options.extra.ga_id && options.cookies.id && process.env.NODE_ENV !== 'development') {
    Vue.use(VueGtag, {
      config: {
        id: options.extra.ga_id,
        params: {
          anonymize_ip: !Cookies.get(options.cookies.id),
        },
      },
      disableScriptLoad: true,
      bootstrap: true,
    }, router.default);
  }

  const { url: baseUrl } = __VUE_WORDPRESS__.routing;
  Vue.prototype.$relativeUrl = (url) => (url
    ? process.env.NODE_ENV === 'development' && process.env.VUE_APP_PROXY_TARGET ? url.replace(process.env.VUE_APP_PROXY_TARGET, '').replace(baseUrl, '').replace(process.env.VUE_APP_BASE_URL, '/')
      : url.replace(baseUrl, '').replace(process.env.VUE_APP_BASE_URL, '/')
    : false
  );

  const app = new Vue({
    router: router.default,
    store: store.default,
    render: (h) => h(App),
  });

  router.default.onReady(() => app.$mount('#app'));
})();

// eslint-disable-next-line
window._iub = [];
// eslint-disable-next-line
window._iub.csConfiguration = {
  lang: 'en-GB',
  siteId: 2542038,
  cookiePolicyId: 15366686,
  consentOnScroll: false,
  consentOnContinuedBrowsing: false,
  banner: {
    applyStyles: false,
    position: 'bottom',
    slideDown: 'true',
    content: 'We use cookies to ensure you get the best experience on our website. %{cookie_policy_link}.',
    cookiePolicyLinkCaption: 'Learn more',
    acceptButtonDisplay: true,
    acceptButtonCaption: 'Accept',
    rejectButtonDisplay: true,
    explicitWithdrawal: true,
    closeButtonDisplay: false,
  },
  callback: {
    onConsentGiven: () => {
      if (window.gtag) {
        window.gtag('consent', 'default', {
          ad_storage: 'granted',
          analytics_storage: 'granted',
        });
      }
    },
    onConsentRejected: () => {
      if (window.gtag) {
        window.gtag('consent', 'default', {
          ad_storage: 'denied',
          analytics_storage: 'denied',
        });
      }
    },
    onBannerClosed: () => {
      Vue.prototype.$bus.$emit('iubendaClose');
    },
    onBannerShown: () => {
      Vue.prototype.$bus.$emit('iubendaReady');
    },
  },
  preferenceCookie: {
    expireAfter: 180,
  },
};

loadScript('https://cdn.iubenda.com/cs/iubenda_cs.js');

document.body.addEventListener('click', (e) => {
  if (e.target.href && e.target.href.includes('#cookie-policy')) {
    e.preventDefault();
    // eslint-disable-next-line
    window._iub.cs.api.openPreferences();
  }
});
