<template>
  <main>
    <a
      href="https://drive.google.com/drive/folders/1kqMgFAyY1yFFFI4EpbJLzNLBTdUdBz7-?usp=sharing"
      target="_blank"
      class="home-link"
    >
      <img
        :src="require('@/assets/images/rayon_green.gif')"
        alt=""
        class="fixed-image"
      >
    </a>
    <!-- <VideoPlayer
      v-if="$mq.isTouchDevice"
      :data="videoMobile"
      class="video"
      style="--ratio: 1;"
    />
    <VideoPlayer
      v-else
      :data="videoDesktop"
      class="video"
      style="--ratio: calc(16/9);"
    /> -->
    <!-- <YtEmbed
      :data="videoIFrame"
      class="video bordered-cell"
    /> -->
  </main>
</template>

<script>
import data from '@/mixins/data';

// import Figure from '@/components/media/figure';
// import VideoPlayer from '@/components/media/video';
// import YtEmbed from '@/components/media/yt-embed';

export default {
  name: 'Home',
  components: {
    // Figure,
    // VideoPlayer,
    // YtEmbed,
  },
  mixins: [data],
  data() {
    return {
      videoDesktop: {
        value: {
          url: '<iframe title="RAYON VERT - THE WISDOM OF THE THIRD WAVE" src="https://player.vimeo.com/video/689987482" width="1920" height="1080" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>',
        },
        settings: {
          source: 'url',
        },
      },
      videoMobile: {
        value: {
          url: '<iframe title="RAYON VERT - THE WISDOM OF THE THIRD WAVE" src="https://player.vimeo.com/video/689988410" width="1000" height="1000" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>',
        },
        settings: {
          source: 'url',
        },
      },
      // videoIFrame: {
      //   // value: { url: '<iframe title="high-speed video" width="640" height="360" src="https://www.youtube.com/embed/videoseries?list=PL8yNO_d1oTwLd8l0tyCvoIeen1y-wIHJW" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>' },
      //   value: { url: '<iframe width="1920" height="1080" src="https://www.youtube.com/embed/R155y3TFIIo?vq=hd1080" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>' },
      //   settings: {
      //     autoplay: true,
      //     filter: false,
      //     blend: 'none',
      //     // background: 'rgb(118, 250, 76)',
      //     background: false,
      //   },
      // },
    };
  },
};
</script>

<style lang="scss" scoped>
.video {
  // padding-bottom: calc(100vh - var(--top) - var(--bottom) - 2px + var(--border-width));
  --h: calc(100vh - var(--top) - var(--bottom) - 2px + var(--border-width));
  width: calc(100% - 2 * var(--edge));
  // height: calc(var(--h) - 0px);
  max-width: calc(var(--h) * var(--ratio, 1));
  // max-height: calc(var(--h) - 0px);
  @include outer-el-border;

  ::v-deep .video-player {
    height: 100%;
    // max-height: calc(var(--h) - 15px);
    @include inner-el-border;
  }
  ::v-deep .plyr {
    height: 100%;
    max-height: calc(var(--h) - 10px);
  }
}

.home-link {
  display: inline;
}

.fixed-image {
  --h: calc(100vh - var(--top) - var(--bottom) + var(--border-width));
  object-fit: contain;
  max-width: calc(100vw - var(--border-width));
  height: auto;
  max-height: calc(var(--h) - 10px);
  @include outer-el-border;
  @include mq(s) {
    height: 100vh;
  }
}
</style>
