<template>
  <div class="block-grid bordered-grid">
    <div
      v-for="(row, rowID) in rows"
      :key="'row-'+rowID"
      class="row bordered-row"
    >
      <div
        v-for="(cell, cellID) in rows[rowID].row"
        :key="'cell-'+cellID"
        :class="['cell', 'bordered-cell', 'span-s-'+cell.span]"
        :style="{'--bg': cell.background, '--mh': cell.min_height}"
      >
        <component
          :is="cell.acf_fc_layout ? layouts.get(cell.acf_fc_layout) : layouts.get('empty')"
          :data="cell.acf_fc_layout !== 'empty' ? cell : null"
          :disable-ratio="cell.acf_fc_layout === 'image' && cell.settings.theme === 'cover'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RichText from '@/components/typo/richtext';
import Figure from '@/components/media/figure';
import YtEmbed from '@/components/media/yt-embed';

const layouts = new Map();
layouts.set('empty', 'div');
layouts.set('text', RichText);
layouts.set('image', Figure);
layouts.set('video', YtEmbed);

export default {
  name: 'Grid',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      layouts,
    };
  },
  computed: {
    rows() {
      return this.data.value.rows || null;
    },
  },
};
</script>

<style lang="scss" scoped>
.block-grid {
  display: grid;
  grid-auto-flow: row;

  .row {
    display: grid;
    grid-auto-flow: row;
    @include mq(s) {
      grid-auto-flow: column;
      grid-auto-columns: 1fr;
    }

    .cell {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: calc(var(--mh) * 1px);
      background: var(--bg);

      .richtext {
        padding: 21px;

        ::v-deep p {
          margin-bottom: 1em;
        }
        ::v-deep img {
          display: block;
          max-width: 100%;

          &.aligncenter {
            margin: 0 auto;
          }
          // width: 100%;
          // height: 100%;
          // object-fit: cover;
        }
      }
      .embed-container {
        width: 100%;
      }
    }
  }
}

.bordered-grid {
  overflow: auto;
}
</style>>
