var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$route.meta.hideHeader),expression:"!$route.meta.hideHeader"}],attrs:{"id":"header"}},[_c('div',{directives:[{name:"scroll-lock",rawName:"v-scroll-lock",value:(_vm.$mq.isMobileS && _vm.menuIsOpen),expression:"$mq.isMobileS && menuIsOpen"}],staticClass:"grid-wrapper"},[_c('div',{staticClass:"block-grid bordered-grid"},[_c('div',{staticClass:"header-row t-center t-uppercase typo--title bordered-row"},[_c('div',{staticClass:"logo bordered-cell"},[_c('div',[_c('img',{attrs:{"src":require('@/assets/svg/wisdomthirdwave.svg')}})])]),_c('Link',{staticClass:"side bordered-cell span-s-2",attrs:{"data":{
            url: 'https://rayonvert.international/',
            title: 'Rayon Vert',
            target: '_blank',
          },"theme":"hover"}}),_c('Link',{staticClass:"bordered-cell span-s-3 gray",attrs:{"data":{
            url: '/',
            title: '&#8220;The Wisdom of the Third Wave&#8221;',
            target: false,
          },"theme":"hover"}}),_c('Link',{staticClass:"side bordered-cell span-s-2",attrs:{"data":{
            url: 'https://slamjam.com/',
            title: 'Slam Jam',
            target: '_blank',
          },"theme":"hover"}})],1),_c('div',{staticClass:"menu-wrapper bordered-row"},[_c('Menu',{staticClass:"menu-row bordered-row span-s-5"}),_c('div',{staticClass:"free-cell bordered-cell span-s-2 gray typo--p t-center",class:{hide: _vm.$route.name === 'Product'}},[_c('div',{staticClass:"free-grid bordered-grid gray"},[_vm._m(0),(!_vm.mc.success && !_vm.mc.already)?_c('div',{staticClass:"email bordered-row",on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.submit.apply(null, arguments)}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],ref:"email",staticClass:"bordered-cell flex-center gray",attrs:{"type":"email","name":"email","placeholder":"YOUR EMAIL HERE","autocomplete":"email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}}),_c('div',{staticClass:"button bordered-cell",on:{"click":_vm.submit}},[_vm._v(" SUBSCRIBE ")])]):(_vm.mc.already)?_c('div',{staticClass:"bordered-row"},[_c('div',{staticClass:"bordered-cell"},[_vm._v(" ALREADY SUBSCRIBED ")])]):(_vm.mc.success)?_c('div',{staticClass:"bordered-row"},[_c('div',{staticClass:"bordered-cell"},[_vm._v(" THANK YOU ")])]):_vm._e()])])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bordered-row"},[_c('div',{staticClass:"free-text bordered-cell"},[_vm._v(" Do you want to know more? Register here for new updates:"),_c('br'),_c('br'),_vm._v(" By clicking on \"SUBSCRIBE\" you agree to receive our newsletter (Read the full "),_c('a',{attrs:{"href":"https://www.iubenda.com/privacy-policy/15366686","target":"_blank"}},[_vm._v("Privacy Policy")]),_vm._v("). ")])])
}]

export { render, staticRenderFns }